var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"px-1"},[_c('v-col',{staticClass:"mt-n3",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"v-card-grey mb-3 px-0 "},[_c('v-card-title',{staticClass:"pt-0 pb-0 mb-0 card-title-bloque"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('p',{staticClass:"tit_card pt-2"},[_vm._v(" "+_vm._s(_vm.$t("Tareas"))+" ")])]),_c('v-col',[_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"hide-details":"","dense":"","flat":"","filled":"","solo":"","placeholder":_vm.$t('Buscar')},model:{value:(_vm.searchTermHelper),callback:function ($$v) {_vm.searchTermHelper=$$v},expression:"searchTermHelper"}},[_c('template',{slot:"prepend-inner"},[_c('v-icon',{attrs:{"color":"#adb5bd","size":".875rem"}},[_vm._v(" fas fa-search ")])],1)],2)],1)],1)],1),_c('v-card-text',{staticClass:"px-0 py-0 pb-4"},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',[_c('v-data-table',{staticClass:"table thead-light",attrs:{"footer-props":{'items-per-page-options': [5, 10, 15], 'items-per-page-text': _vm.$t('Items por página') },"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"options":_vm.options,"server-items-length":_vm.totalItems,"loading-text":_vm.$t('Cargando datos... Espere por favor.'),"no-data-text":_vm.$t('No hay resultados.')},on:{"update:page":function($event){_vm.page=$event},"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("shortChars")(_vm.$t(item.name),25))+" ")])]}},{key:"item.task_id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getTypeTranslated(item.task_id))+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateLocal")(item.created_at,'DD/MM/YYYY HH:mm'))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 1)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Resuelto'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('Pendiente'))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"blue "}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" fas fa-info-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Info'))+" ")])])]}}])}),_c('v-row',[_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[(_vm.items.length)?_c('v-pagination',{staticClass:"pagination",staticStyle:{"margin-top":"-52px"},attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"#0094de","length":_vm.pageCount,"total-visible":10,"disabled":_vm.disabledPagination,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }