import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import TaskListService from '@/services/assistant/task-list.service.js'
import { Task } from '@/models/assistant/task.js'
import RoutesService from '@/services/whatsapp/routes.service'
import { Subscriptions } from '@/classes/helpers/Subscriptions'

export default {
  name: 'TaskList',
  components: {
    HeaderTopDashboard,
  },
  props: { },
  data () {
    return {
      items: [],
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      total: 0,
      disabledPagination: true,
      options: {},
      searchTerm: '',
      totalItems: 0,
      debouncedInput: '',
      timeout: null,
    }
  },
  methods: {

    getParams () {
      const page = this.items.length === 1 && this.options.page > 1
      ? this.options.page - 1
      : this.options.page

      const params = {
        page: page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },
    getTasksList () {
      const data = this.getParams()
      this.disabledPagination = true

      TaskListService.getTasksList(data).then((response) => {
        this.items = response.data
        this.itemsPerPage = parseInt(response.per_page)
        this.page = response.current_page
        this.totalItems = response.total
        this.disabledPagination = false
      })
    },
  getTypeTranslated (type) {
        switch (type) {
            case Task.KNOWLEDGE_DROPPED:
                return this.$t('Conocimiento caido')
            case Task.LACK_OF_INFORMATION:
                return this.$t('Falta de información')
            case Task.INCORRECT_RESPONSE:
                return this.$t('Respuesta incorrecta')
            case Task.VALIDATE_RESPONSE_BY_AN_AGENT:
                return this.$t('Validar respuesta por un agente')
            default:
                return this.$t('tipo desconocido')
        }
    },
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('Nombre'),
          value: 'name',
        },
        {
          text: this.$t('Id'),
          value: 'assistant_id',
        },
        {
          text: this.$t('Tipo'),
          value: 'task_id',
        },
        {
          text: this.$t('Mensaje'),
          value: 'message',
        },
        {
          text: this.$t('Fecha de creación'),
          value: 'created_at',
        },
        {
          text: this.$t('Estado'),
          value: 'status',
        },
        {
          text: this.$t('Acciones'),
          value: 'actions',
          sortable: false,
        },
      ]
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
          this.options.page = 1
        }, 700)
      },
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
      }
    },
  },
  watch: {
    params: {
      handler () {
        this.getTasksList()
      },
      deep: true,
    },
  },
  mounted () {
  },
  created () {
    RoutesService.getCurrentUserAllRoutes()
    .then((routes) => {
      Subscriptions.checkSubscription(
        Subscriptions.CHATBOTS,
        () => {
          this.$router.push({ name: this.$route.name, query: this.$route.query }).catch(() => { this.$forceUpdate() })
        },
        () => this.$router.push({ name: 'ChatBotsSubscriptionNeeded' }).catch(() => { this.$forceUpdate() }),
      )
    })
  },
}
