
import { Service } from '@/services/service'

const PATH = 'ai/assistant/tasks/'

class TaskListService extends Service {
  getTasksList (params) {
    return this.get(PATH + 'list', params)
  }
}

export default new TaskListService()
